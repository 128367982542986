import Swiper, { Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination]);

const allSwiper = document.querySelectorAll(".swiper");

if (allSwiper) {
	const swiper = Array.from(allSwiper).map((swiper) => {
		const { autoplay, delay, effect, items, loop, space, speed } = swiper.dataset;

		const options = {
			autoHeight: true,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			pagination: {
				el: ".swiper-pagination",
			},
			loop: loop == "true",
			speed: parseInt(speed),
			slidesPerView: items,
			spaceBetween: parseInt(space),
			autoplay:
				autoplay == "true"
					? {
							delay: parseInt(delay) ?? 4000,
					  }
					: false,
		};

		return new Swiper(swiper, options);
	});
	console.log(swiper);
}
