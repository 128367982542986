const navOpen = document.querySelector("#nav-open");
const navClose = document.querySelector("#nav-close");
const navElement = document.querySelector("#nav-element");

if (navOpen && navClose) {
	navOpen.addEventListener("click", () => {
		navElement.classList.remove("-top-[100vh]");

		Array.from(navElement.querySelectorAll(".wp-block-navigation__container li")).forEach((elem, i) => {
			setTimeout(() => {
				elem.style.opacity = 1;
				elem.style.left = 0;
			}, (i + 1) * 200);
		});
	});

	navClose.addEventListener("click", () => {
		navElement.classList.add("-top-[100vh]");

		Array.from(navElement.querySelectorAll(".wp-block-navigation__container li")).forEach((elem, i) => {
			setTimeout(() => {
				elem.style.opacity = 0;
				elem.style.left = 24;
			}, (i + 1) * 200);
		});
	});
}
