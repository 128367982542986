import mapboxgl from "mapbox-gl";
import "dotenv/config";

mapboxgl.accessToken = process.env.MAPBOX_TOKEN;

const maps = document.querySelectorAll(".karte");
if (maps) {
	Array.from(maps).forEach((elem) => {
		const coords = JSON.parse(elem.dataset.coords);

		const center = elem.dataset.center.split(",");
		const zoom = elem.dataset.zoom || 14;

		const map = new mapboxgl.Map({
			container: elem,
			style: "mapbox://styles/uhorn/cl0thz275002215s9z2mgy1ws", // style URL
			center: center, // starting position [lng, lat]
			zoom: zoom, // starting zoom
		});
		map.scrollZoom.disable();

		coords.forEach((coord) => {
			const el = document.createElement("div");

			if (coord.label == "Parkplatz") {
				el.className = "parkplatz";
				el.innerHTML = `<span class="text-white bg-blue-700 h-4 w-4 rounded-full items-center justify-center flex text-sm">P</span>`;
			} else {
				el.className = "marker";
				el.innerHTML = `<span></span>`;
			}

			const marker = new mapboxgl.Marker(el);
			marker.setLngLat(coord.coords.split(",")).addTo(map);
		});
	});
}
